import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { GetVersion, GetVersionResponse } from "../../api/fetcher";
import { useMainContext } from "../../MainContext";
import { OverviewLinkType } from "../../utils/graphUtils";
import { getDisplayWorkloadName, workloadTypes } from "../../utils/namesUtils";
import { getWorkloadType } from "../../utils/typesUtils";
import ChartTooltipElement from "../ChartTooltipElement";

const VERSION_THRESHOLD = "1.5.2";

interface Props {
  color: string;
  value: React.ReactNode;
  label: React.ReactNode;
  rawLabel: string;
  openInNewTab?: boolean;
  disableLink?: boolean;
  textToCopyOnClick?: string;
  source?: OverviewLinkType;
  targetResource?: OverviewLinkType;
  secondaryValue?: React.ReactNode;
  hasLimitedWidth?: boolean;
}

export const buildNodeOverviewLink = (nodeId: string, currentCluster?: string) => {
  const currentClusterURLParam = currentCluster ? `currentClusterURLParam=${currentCluster}` : "";
  const searchTermUrlParam = `setSearchTerm=${nodeId.toLocaleLowerCase()}`;
  return `/nodes?${currentClusterURLParam}&${searchTermUrlParam}&openFirstRowNodeOverview=1`;
};

const { queryKey, queryFn } = GetVersion();
const getIsVersionXGreaterThanVersionY = (versionX: string | undefined, versionY: string | undefined): boolean => {
  if (!versionX || !versionY) {
    return false;
  }
  const versionXArray = versionX.split(".");
  const versionYArray = versionY.split(".");
  for (let i = 0; i < versionXArray.length; i++) {
    if (parseInt(versionXArray[i]) >= parseInt(versionYArray[i])) {
      return true;
    }
  }
  return false;
};

const LinkableTooltipElement = ({
  color,
  value,
  label,
  rawLabel,
  openInNewTab = true,
  disableLink,
  textToCopyOnClick,
  source = OverviewLinkType.Workload,
  targetResource = OverviewLinkType.Workload,
  secondaryValue,
  hasLimitedWidth,
}: Props) => {
  const { currentCluster } = useMainContext();
  const [link, setLink] = useState<string>("");
  const { data } = useQuery<GetVersionResponse, Error>({
    queryKey: [queryKey, currentCluster],
    queryFn: queryFn,
    enabled: !disableLink,
  });

  const currentClusterURLParam = currentCluster ? `currentClusterURLParam=${currentCluster}` : "";

  const buildWorkloadOverviewLink = (splitLabel: string[]) => {
    const isVersionXGreaterOrEqualThanVersionY = getIsVersionXGreaterThanVersionY(
      data?.currentVersion,
      VERSION_THRESHOLD
    );
    let cluster = "";
    let namespace = "";
    let workloadName = "";
    let type = "";
    const splitLen = splitLabel.length;
    switch (true) {
      case source === OverviewLinkType.Node:
        type = splitLabel[1] ?? "";
        namespace = splitLabel[0] ?? "";
        workloadName = splitLabel[2] ?? "";
        break;
      case source === OverviewLinkType.Workload:
        type = splitLabel[splitLen - 3] ?? "";
        if (isVersionXGreaterOrEqualThanVersionY) {
          namespace = splitLabel[splitLen - 2] ?? "";
          workloadName = splitLabel[splitLen - 1] ?? "";
        } else {
          namespace = splitLabel[splitLen - 1] ?? "";
          workloadName = splitLabel[splitLen - 2] ?? "";
        }
        break;
      case source === OverviewLinkType.MultiCluster:
        cluster = splitLabel[0] ?? "";
        type = splitLabel[1] ?? "";
        namespace = splitLabel[2] ?? "";
        workloadName = splitLabel[3] ?? "";
        break;
    }
    type = getWorkloadType(workloadName, type) ?? type;
    type = workloadTypes?.[type as keyof typeof workloadTypes] ?? type;
    workloadName = getDisplayWorkloadName(workloadName);

    const typeUrlParam = `types=${type}`;
    const searchTermUrlParam = `searchTerms=${namespace}/${workloadName}`;

    if (cluster != "") {
      const clusterURLParam = `currentClusterURLParam=${cluster}`;
      return `/?${clusterURLParam}&${searchTermUrlParam}&${typeUrlParam}&openFirstRowWorkloadOverview=1`;
    }

    return `/?${currentClusterURLParam}&${searchTermUrlParam}&${typeUrlParam}&openFirstRowWorkloadOverview=1`;
  };

  useEffect(() => {
    const splitLabel = rawLabel.split("/");

    switch (true) {
      case targetResource === OverviewLinkType.Node: {
        const cluster = splitLabel[0] ?? "";
        const nodeId = splitLabel[0] ?? "";
        setLink(buildNodeOverviewLink(nodeId, cluster));
        return;
      }
      case targetResource === OverviewLinkType.Workload: {
        //TODO: split this code by row data values
        setLink(buildWorkloadOverviewLink(splitLabel));
        return;
      }
    }
  }, [data]);

  if (disableLink) {
    return (
      <ChartTooltipElement
        color={color}
        value={value}
        secondaryValue={secondaryValue}
        label={label}
        enableCopyTextOnClick={textToCopyOnClick}
        hasLimitedWidth={hasLimitedWidth}
      />
    );
  }

  return (
    <NavLink to={link} target={openInNewTab ? "_blank" : undefined}>
      <ChartTooltipElement
        color={color}
        value={value}
        secondaryValue={secondaryValue}
        label={label}
        className={clsx({
          "hover:text-main-linkBlue hover:underline": !disableLink,
        })}
        enableCopyTextOnClick={textToCopyOnClick}
        hasLimitedWidth={hasLimitedWidth}
      />
    </NavLink>
  );
};

export default LinkableTooltipElement;
