import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { SCALEOPS_COLORS } from "../../../colors";
import useFreezeTooltip from "../../../pages/Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { customNumberFormatter } from "../../../utils/formatterUtils";
import Button, { BUTTON_VARIANTS } from "../../Button";
import AxisTooltip, { XAxisTooltip } from "./AxisTooltip";
import CustomTooltip from "./CustomTooltip";
import { CHART_COLORS, getDisplayName, GraphPoint, ROW_DATA, ScaleDownBlockers } from "./utils";

const MIN_ELEMENTS = 10;
const MAX_ELEMENTS = 80;
const RANDOM_COLORS_LENGTH = SCALEOPS_COLORS.randomColors.length;

interface Props {
  data: GraphPoint[];
}

const ScheduleBlockersGraph = ({ data }: Props) => {
  const [existingColors, setExistingColors] = useState<Record<string, string>>({});
  const { tooltipTrigger, updateActiveTooltips } = useFreezeTooltip({});

  const [uniqueReasons, setUniqueReasons] = useState<string[]>([]);
  const [uniqueNames, setUniqueNames] = useState<string[]>([]);
  const [maxElements, setMaxElements] = useState<number>(MIN_ELEMENTS);

  const hasNoData = !data || data.length === 0;
  const numberOfXTickLines = data.length || undefined;

  const [tooltip, setTooltip] = useState<XAxisTooltip>(null);

  const maxValue = data.reduce((acc, curr) => {
    const currMax = Math.max(
      ...Object.values(curr)
        .filter((value) => !isNaN(Number(value)))
        .map((value) => Number(value))
    );
    return currMax > acc ? currMax : acc;
  }, 0);

  useEffect(() => {
    if (!data?.length) return;

    if (!uniqueReasons?.length) {
      const uniqueReasons = data
        ?.map((d) => Object.keys(d).filter((key) => key && key !== "name" && key !== ROW_DATA))
        .flat();
      setUniqueReasons(uniqueReasons);
    }

    if (!uniqueNames?.length) {
      const uniqueNamesValue: string[] = [...new Set(data?.map((d) => String(d.name)))];
      setUniqueNames(uniqueNamesValue);
    }
  }, [data, maxElements]);

  return (
    <div>
      <div className="w-full relative h-[250px] flex flex-col items-start">
        <Typography variant="body1" fontWeight={700} className="select-none">
          Blocked nodes by actions
        </Typography>
        <ResponsiveContainer height="100%" width="100%">
          <BarChart
            data={data.slice(0, maxElements)}
            margin={{
              top: 20,
              right: 30,
              left: -20,
              bottom: 5,
            }}
          >
            <Tooltip
              content={
                <CustomTooltip
                  updateActiveTooltips={updateActiveTooltips}
                  tooltipTrigger={tooltipTrigger}
                  colors={existingColors}
                />
              }
              cursor={{ fill: SCALEOPS_COLORS.background.ghostWhite }}
              trigger={tooltipTrigger}
              wrapperStyle={{
                outline: "none",
                pointerEvents: "auto",
                zIndex: 999,
              }}
            />
            <XAxis
              dataKey="name"
              style={{ fontSize: "x-small" }}
              strokeWidth={2}
              tickFormatter={(value: string) => {
                if (hasNoData) return "";
                const displayName = getDisplayName(value as ScaleDownBlockers);
                return displayName;
              }}
              tickLine={hasNoData ? false : undefined}
              tickCount={numberOfXTickLines}
              onMouseEnter={(params) => {
                setTooltip({
                  ...params,
                  value: getDisplayName((params as unknown as { value?: string })?.value ?? ""),
                } as unknown as XAxisTooltip);
              }}
              onMouseLeave={() => setTooltip(null)}
            />
            <YAxis
              style={{ fontSize: "x-small" }}
              strokeWidth={2}
              tickFormatter={(tick: number) => {
                return hasNoData || tick === undefined ? "" : String(customNumberFormatter(tick));
              }}
              tickLine={hasNoData ? false : undefined}
              tickCount={maxValue > 5 ? undefined : 2}
              allowDecimals={false}
            />
            {uniqueReasons.map((key, index) => {
              let color;
              let randomColorIndex = 0;
              switch (true) {
                case key in CHART_COLORS:
                  color = CHART_COLORS[key as keyof typeof CHART_COLORS];
                  break;
                case key in existingColors:
                  color = existingColors[key];
                  break;
                default:
                  randomColorIndex = Math.floor(Math.random() * RANDOM_COLORS_LENGTH);
                  existingColors[key] = SCALEOPS_COLORS.randomColors[randomColorIndex];
                  color = SCALEOPS_COLORS.randomColors[randomColorIndex];
                  setExistingColors({
                    ...existingColors,
                    [key]: SCALEOPS_COLORS.randomColors[randomColorIndex],
                  });
              }

              return (
                <Bar
                  dataKey={key}
                  stackId="a"
                  fill={color}
                  key={key}
                  radius={index === uniqueReasons.length - 1 ? [5, 5, 0, 0] : undefined}
                />
              );
            })}
          </BarChart>
        </ResponsiveContainer>
        <AxisTooltip tooltip={tooltip} />
        {data.length > MIN_ELEMENTS ? (
          <div className="flex items-center justify-center gap-2 absolute right-[40px] bottom-[-27px]">
            <Typography
              variant="caption"
              fontSize={10}
              className="text-text-lightBlack italic"
              style={{
                zIndex: 999,
              }}
            >
              showing {maxElements} of {data.length} nodes
            </Typography>
            <Button
              label={maxElements === MIN_ELEMENTS ? `Show more` : "Show less"}
              onClick={() =>
                setMaxElements((s) => {
                  if (s === MIN_ELEMENTS) return Math.min(data?.length, MAX_ELEMENTS);
                  return MIN_ELEMENTS;
                })
              }
              variant={BUTTON_VARIANTS.extraSmall}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ScheduleBlockersGraph;
