import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { components } from "../../../api/schema";
import { useMainContext } from "../../../MainContext";
import { NODE_ACTIONS_ID } from "../../autoscalers/NodesActions";
import Button, { BUTTON_VARIANTS } from "../../Button";
import { OPTIMIZE_UNEVICTABLE_PODS_TITLE_ID } from "../UnevictableTable";

const BUTTON_CLASS_NAME = "w-[240px]";

export enum BlockedNodeReasons {
  OptimizeUnevictablePods = "OptimizeUnevictablePods",
  ExploreSchedulingReasons = "ExploreSchedulingReasons",
  ExploreNodesRestriction = "ExploreNodesRestriction",
  ChangeClusterAutoScalerConfiguration = "ChangeClusterAutoScalerConfiguration",
}

interface Props {
  params: components["schemas"]["NodeGroupsBlockedNodeTableEntry"];
}

const NodeAction = ({ params }: Props) => {
  const navigate = useNavigate();
  const { currentCluster } = useMainContext();
  const [content, setContent] = React.useState<React.ReactNode | null>(null);

  useEffect(() => {
    switch (params.category) {
      case BlockedNodeReasons.OptimizeUnevictablePods:
        setContent(
          <Button
            onClick={() => {
              document.getElementById(OPTIMIZE_UNEVICTABLE_PODS_TITLE_ID)?.scrollIntoView({ behavior: "smooth" });
            }}
            label={"Optimize unevictable pods"}
            variant={BUTTON_VARIANTS.regular}
            className={BUTTON_CLASS_NAME}
          />
        );
        break;
      case BlockedNodeReasons.ExploreSchedulingReasons:
        setContent(
          <Button
            onClick={() => {
              navigate(
                `/?currentClusterURLParam=${
                  currentCluster ?? ""
                }&schedulerBlockers=InsufficientAllocationForResourceRequests&schedulerBlockers=SchedulingReason-PodAffinityConstraint&schedulerBlockers=SchedulingReason-PodAnti-affinityConstraint`
              );
            }}
            label={"Explore workloads"}
            variant={BUTTON_VARIANTS.regular}
            className={BUTTON_CLASS_NAME}
          />
        );
        break;
      case BlockedNodeReasons.ChangeClusterAutoScalerConfiguration:
        setContent(
          <Button
            onClick={() => {
              navigate(`/nodes#${NODE_ACTIONS_ID}?currentClusterURLParam=${currentCluster ?? ""}`);
            }}
            label={"Change cluster configuration"}
            variant={BUTTON_VARIANTS.regular}
            className={BUTTON_CLASS_NAME}
          />
        );
        break;
      case BlockedNodeReasons.ExploreNodesRestriction:
        setContent(
          <Button
            onClick={() => {
              navigate(
                `/nodes?currentClusterURLParam=${currentCluster ?? ""}&selectedNodeOverviewId=${
                  params.node ?? ""
                }&nodeOverviewSelectedTab=Yaml`
              );
            }}
            label={"Explore node YAML"}
            variant={BUTTON_VARIANTS.regular}
            className={BUTTON_CLASS_NAME}
          />
        );
        break;
      default:
        setContent(null);
    }
  }, [params.blockedReason]);

  return <div>{content}</div>;
};

export default NodeAction;
