import { RestartAlt, Restore } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import numeral from "numeral";
import pluralize from "pluralize";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ArrayParam, BooleanParam, StringParam, useQueryParam } from "use-query-params";
import AutomationIcon from "../../Icons/AutomationIcon";
import BulkIcon from "../../Icons/BulkIcon";
import CircleIconWrapper from "../../Icons/CircleIconWrapper";
import DownIcon from "../../Icons/DownIcon";
import PolicyIcon from "../../Icons/PolicyIcon";
import UnAutomateIcon from "../../Icons/UnAutomateIcon";
import UnFilterIcon from "../../Icons/UnFilterIcon";
import { useWorkloadsContext } from "../../WorkloadsContext";
import { components } from "../../api/schema";
import { SCALEOPS_COLORS } from "../../colors";
import { getWastedResourceDisplayValue } from "../../pages/Overview/PolicyTuning/WastedResourcesWarning";
import { PolicyType } from "../../pages/Policies/utils";
import { IsFreeDistribution } from "../../utils/FeaturesHelper";
import { SpliceIntoChunks } from "../../utils/arrayUtils";
import { HAS_NEW_WORKLOADS_FILTERS } from "../../utils/developmentFeatureFlags";
import { EXCLUDE_SIGN, INCLUDE_SIGN, workloadTypes } from "../../utils/namesUtils";
import { AvailableSavingsFilter, enableFilterByUrlParam, FilterByUrlParam } from "../../utils/queryParams";
import { ScaleOpsProduct } from "../../utils/typesUtils";
import { setBooleanUrlParam } from "../../utils/urlUtils";
import useIsReadyOnlyFrontEnd from "../../utils/useIsReadyOnlyFrontEnd";
import Button from "../Button";
import CustomColumnsFilterButton from "../CustomColumnsFilterButton";
import CustomFilterChip from "../CustomFilterChip";
import CustomListItem from "../CustomListItem";
import CustomSelectedFilterChip from "../CustomSelectedFilterChip";
import Input from "../Input";
import MultiSelect, { camelCaseToSnakeSpaceOptionRenderFunction } from "../MultiSelect";
import MultiSelectByQueryParams from "../MultiSelectByQueryParams";
import SnapshotWrapper from "../SnapshotWrapper";
import { default as Tooltip } from "../Tooltip";
import YouHaveReadOnlyAccess from "../YouHaveReadOnlyAccess";
import useWindowSize from "../useWindowSize";
import { KeyValueMultiSelect } from "./KeyValueMultiSelect";
import AutomateMenuItemDiv from "./AutomateMenuItemDiv";
import BulkActions from "./BulkActions";
import WorkloadsFilters from "./Filters/WorkloadsFilters";
import MultiSelectWastedResourcesByQueryParams from "./MultiSelectWastedResourcesByQueryParams";
import NamespacesActionsMenu from "./NamespacesActionsMenu";
import { CappedStatus, cappedStatusToDescription } from "./OverviewHooks/useGetCappedStatuses";
import SelectActionItem from "./SelectActionItem";
import { WorkloadTableFilter, WorkloadTableFilterToHandle } from "./WorkloadStatusByNamespace";
import { HpaOptimizationTypes, RolloutStrategyTypes, UnevictableTypes, WorkloadWarningsTypes } from "./overviewUtils";
import useClearWorkloadsOverviewFilters, { NAMESPACES_QUERY_KEY } from "./useClearWorkloadsOverviewFilters";
import useHpaOptimizationEnabled from "./useHpaOptimizationEnabled";
import useOverProvisionedFilter, { OverProvisionedTypes, UnderProvisionedTypes } from "./useOverProvisionedFilter";

const HAS_NEW_ACTION_MENU = true;

const YOU_HAVE_SELECTED_NON_EDITABLE_TEXT = (
  <Typography variant="caption">
    You don't have <b>permission to automate</b> some of the selected workloads.
  </Typography>
);
const FILTER_GAP = 8;

interface GetRefProps {
  filtersRef: React.MutableRefObject<HTMLDivElement[]>;
  el: HTMLDivElement;
}

const getRef = ({ filtersRef, el }: GetRefProps) => {
  filtersRef.current.push(el);
  const uniqueFilters = filtersRef.current.filter(
    (value, index, self) => self.findIndex((t) => t?.innerHTML === value?.innerHTML) === index
  );
  filtersRef.current = uniqueFilters;
};

const showMoreOrLessClassName =
  "h-[34px] w-[150px] border border-border rounded-full px-4 flex items-center justify-center bg-white text-guideline-lessDarkPurple hover:text-guideline-darkPurple hover:bg-background-chip cursor-pointer text-[14px]";

export enum KeyValueOptions {
  KeyValue = "Key=Value",
  Key = "Key",
  Value = "Value",
}

export enum OverviewTableColumns {
  // Workload = "Workload",
  TotalCost = "Total Cost",
  SavingsAvailable = "Savings Available (monthly)",
  ActiveSavings = "Active Savings (monthly)",
  CpuRequests = "CPU Request",
  OriginalCpuRequests = "Original CPU Request",
  MemoryRequests = "Memory Request",
  OriginalMemoryRequests = "Original Memory Request",
  Replicas = "Replicas",
  ReadyReplicas = "Ready Replicas",
  MinReplicasDiff = "Min Replicas",
  Policy = "Policy",
  GpuRequests = "GPU Request",
  Unevcitable = "Unevictable",
  // Actions = "Actions",
  // Automated = "Automated",
}

export type WorkloadLabel = {
  key: string;
  value: string;
};

export type WorkloadAnnotation = {
  key: string;
  value: string;
};

interface EditToolbarProps {
  setSelectedRows: React.Dispatch<React.SetStateAction<components["schemas"]["UtilsWorkload"][] | undefined>>;
  selectedRows?: components["schemas"]["UtilsWorkload"][];
  fetchWorkloads: () => void;
  updateAutoInBulk: (
    checked: boolean,
    workloads: {
      name: string;
      type: string;
      namespace: string;
      auto: boolean;
      id: string;
      isAutomationExcluded: boolean | undefined;
    }[]
  ) => Promise<void>;
  applyInBulk: (
    workloads: {
      name: string;
      type: string;
      namespace: string;
      muttype: string;
    }[]
  ) => void;
  automateByFilter: (state: boolean) => void;
  namespaces: string[];
  workloadsLabels: WorkloadLabel[];
  workloadsAnnotations: WorkloadAnnotation[];
  types: string[];
  priorityClassNames: string[];
  optimizationGaps: string[];
  policies: (components["schemas"]["V1alpha1Policy"] | components["schemas"]["V1alpha1HpaPolicy"])[];
  attachPolicyToNamespace: (policyName: string) => void;
  attachPolicyToCluster: (policyName: string) => void;
  attachPolicyInBulk: (
    policyName: string,
    workloads: { name: string; type: string; namespace: string; id: string; rollbackPolicyName: string }[]
  ) => Promise<void>;
  restartInBulk: (workloads: { name: string; type: string; namespace: string }[]) => Promise<void>;
  restoreClusterPolicy: () => void;
  restoreNamespacesPolicy: () => void;
  restorePolicyInBulk: (workloads: { name: string; type: string; namespace: string; id: string }[]) => Promise<void>;
  selectedColumns: (string | undefined)[];
  setSelectedColumns: React.Dispatch<React.SetStateAction<(string | undefined)[]>>;
  availableColumns: OverviewTableColumns[];
  canEditAllWorkloads: boolean | undefined;
  namespaceAnnotations: string[];
  serializedCappedStatuses: string[];
  numberOfRows: number;
  scaleOpsProduct: ScaleOpsProduct | undefined;
}

const MAX_NAMESPACES_FOR_BULK_ACTIONS = 5;

const hpaOptimizationTypeRenderFunction = (
  option: string,
  index: number,
  selected: (string | undefined)[],
  dataTestId?: string
) => (
  <MenuItem
    value={option}
    key={`${index}-multi-select-option`}
    data-testid={dataTestId ? `${dataTestId}-option-${index}` : undefined}
    className="min-w-fit"
  >
    <div className="min-w-[235px] flex items-center">
      <Checkbox checked={selected.indexOf(option) > -1} />
      <ListItemText primary={HpaOptimizationTypes[option as keyof typeof HpaOptimizationTypes]} />
    </div>
  </MenuItem>
);

const EditToolbar = ({
  setSelectedRows,
  selectedRows,
  fetchWorkloads,
  updateAutoInBulk,
  namespaces,
  workloadsLabels,
  workloadsAnnotations,
  types,
  priorityClassNames,
  optimizationGaps,
  automateByFilter,
  policies,
  attachPolicyToNamespace,
  attachPolicyToCluster,
  attachPolicyInBulk,
  restartInBulk,
  restoreClusterPolicy,
  restoreNamespacesPolicy,
  restorePolicyInBulk,
  selectedColumns,
  setSelectedColumns,
  availableColumns,
  canEditAllWorkloads,
  namespaceAnnotations,
  serializedCappedStatuses,
  numberOfRows,
  scaleOpsProduct,
}: EditToolbarProps) => {
  const isHPAProduct = scaleOpsProduct === ScaleOpsProduct.HPA;
  const hasNonEditableWorkloadsSelectedRows = selectedRows?.some((row) => row && !row?.isEditable) || false;
  const numberOfSelectedRows = selectedRows?.length ? numeral(selectedRows?.length).format("0,0a") : 0;

  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const clearWorkloadsOverviewFilters = useClearWorkloadsOverviewFilters({ setSelectedRows });
  const { updateOverriddenWorkloads } = useWorkloadsContext();
  const [showAllUsedFilterChips, setShowAllUsedFilterChips] = useState<boolean>(false);
  const [showFilterArrow, setShowFilterArrow] = useState<boolean>(false);
  const [innerCurrentSearchTerm, setInnerCurrentSearchTerm] = useState<string | undefined>("");

  const activeFilterWrapperRef = useRef<HTMLDivElement>(null);

  const selectedRowsPolicyNames = selectedRows?.map((selected) => selected?.policyName);

  const defaultSelectedPolicyName = selectedRowsPolicyNames?.every((val, _, arr) => val === arr[0])
    ? selectedRowsPolicyNames[0]
    : undefined;

  const [policyNameToWorkload, setPolicyNameToWorkload] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [filters, setFilters] = React.useState<WorkloadTableFilter[]>([]);
  const [searchTerms, setSearchTerms] = useQueryParam("searchTerms", ArrayParam);
  const [currentSearchTerm, setCurrentSearchTerm] = useQueryParam("currentSearchTerm", StringParam);
  const [workloadErrors, setWorkloadErrors] = useQueryParam("workloadErrors", ArrayParam);
  const [logicalWorkloadErrors] = useQueryParam("logicalWorkloadErrors", StringParam);
  const [workloadErrorsExclude] = useQueryParam("isWorkloadErrorsExclude", BooleanParam);
  const [namespacesParams, setNamespacesParams] = useQueryParam(NAMESPACES_QUERY_KEY, ArrayParam);
  const [namespaceAnnotationParam, setNamespaceAnnotationParam] = useQueryParam("namespaceAnnotation", ArrayParam);
  const [updateModeParams, setUpdateModeParams] = useQueryParam("updateMode", ArrayParam);
  const [isNamespacesExclude] = useQueryParam("isNamespacesExclude", BooleanParam);
  const [isNamespaceAnnotationExclude] = useQueryParam("isNamespaceAnnotationExclude", BooleanParam);
  const [isUpdateModeExclude] = useQueryParam("isUpdateModeExclude", BooleanParam);
  const [policy, setPolices] = useQueryParam("policy", ArrayParam);
  const [isPolicyExclude] = useQueryParam("isPolicyExclude", BooleanParam);
  const [typesParams, setTypesParams] = useQueryParam("types", ArrayParam);
  const [isTypesExclude] = useQueryParam("isTypesExclude", BooleanParam);
  const [, setCpuOverProvisionedParam] = useQueryParam("overProvisionedCpu", StringParam);
  const [, setCpuUnderProvisionedParam] = useQueryParam("underProvisionedCpu", StringParam);
  const [, setMemoryOverProvisionedParam] = useQueryParam("overProvisionedMemory", BooleanParam);
  const [, setMemoryUnderProvisionedParam] = useQueryParam("underProvisionedMemory", BooleanParam);

  const [isAutomated, setIsAutomated] = useQueryParam("isAutomated", BooleanParam);
  const [isSpotFriendly, setIsSpotFriendly] = useQueryParam("spotFriendly", BooleanParam);
  const [isSpotUnfriendly, setIsSpotUnfriendly] = useQueryParam("spotUnfriendly", BooleanParam);
  const [isUnAutomated, setIsUnAutomated] = useQueryParam("isUnAutomated", BooleanParam);
  const [hasMinReplicasSavings, setHasMinReplicasSavings] = useQueryParam("hasMinReplicasSavings", BooleanParam);
  const [hasHpaAutomatedPolicy, setHasHpaAutomatedPolicy] = useQueryParam("hasHpaAutomatedPolicy", BooleanParam);
  const [hasHpaUnAutomatedPolicy, setHasHpaUnAutomatedPolicy] = useQueryParam("hasHpaUnAutomatedPolicy", BooleanParam);
  const [misconfiguredHpa, setMisconfiguredHpa] = useQueryParam("misconfiguredHpa", BooleanParam);
  const [hasHpa, setHasHpa] = useQueryParam("hasHpa", BooleanParam);
  const [hasGPU, setHasGPU] = useQueryParam("hasGPU", BooleanParam);
  const [hpaOptimizationTypeParams, setHpaOptimizationTypeParams] = useQueryParam("hpaOptimizationType", ArrayParam);
  const [isHpaOptimizationTypeExclude] = useQueryParam("isHpaOptimizationTypeExclude", BooleanParam);
  const [logicalHpaOptimizationType] = useQueryParam("logicalHpaOptimizationType", StringParam);
  const [priorityClassNamesParams, setPriorityClassNamesParams] = useQueryParam("priorityClassNames", ArrayParam);
  const [optimizationGapsParams, setOptimizationGapsParams] = useQueryParam("optimizationGaps", ArrayParam);
  const [selectedLabelNames, setSelectedLabelNames] = useQueryParam("labels", ArrayParam);
  const [logicalLabel] = useQueryParam("logicalLabel", StringParam);
  const [selectedAnnotationNames, setSelectedAnnotationNames] = useQueryParam("annotations", ArrayParam);
  const [unevictableTypes, setUnevictableTypes] = useQueryParam("unevictableTypes", ArrayParam);
  const [logicalNamespaceAnnotations] = useQueryParam("logicalNamespaceAnnotation", StringParam);
  const [isPriorityClassNamesExclude] = useQueryParam("isPriorityClassNamesExclude", BooleanParam);
  const [isOptimizationGapsExclude] = useQueryParam("isOptimizationGapsExclude", BooleanParam);
  const [labelsParamsExclude] = useQueryParam("isLabelsExclude", BooleanParam);
  const [annotationsParamsExclude] = useQueryParam("isAnnotationsExclude", BooleanParam);
  const [logicalAnnotation] = useQueryParam("logicalAnnotation", StringParam);

  const [namespaceAnnotationLogicalOperator] = useQueryParam("namespaceAnnotationsLogicalOperator", StringParam);

  const [isCappingStatusExclude] = useQueryParam("isCappingStatusExclude", BooleanParam);
  const [selectedCappingStatuses, setSelectedCappingStatuses] = useQueryParam("cappingStatuses", ArrayParam);
  const [logicalCappingStatus] = useQueryParam("logicalCappingStatus", StringParam);

  const [outOfMemory, setOutOfMemory] = useQueryParam("outOfMemory", BooleanParam);
  const [wastedResourcesParam, setWastedResourcesParam] = useQueryParam("wastedResources", ArrayParam);
  const [wastedResourcesExclude] = useQueryParam("isWastedResourcesExclude", BooleanParam);
  const [logicalWastedResources] = useQueryParam("logicalWastedResources", StringParam);
  const [availableSavingsFilter, setAvailableSavingsFilter] = useQueryParam("availableSavingsFilter", StringParam);
  const hpaOptimizationEnabled = useHpaOptimizationEnabled();

  const {
    overProvisioned2,
    underProvisioned2,
    underProvisionedFilter,
    overProvisionedFilter,
    setOverProvisionedFilter,
    setUnderProvisionedFilter,
  } = useOverProvisionedFilter();
  interface WorkloadOption {
    name: JSX.Element | string;
    callback?: (selectedRows?: components["schemas"]["UtilsWorkload"][], option?: string) => void;
    isDisabled: ({
      numberOfSelectedRows,
      isReadyOnlyFrontEnd,
      hasInActiveOrExcludedFromAutomationWorkloadsSelected,
    }: {
      numberOfSelectedRows: string | 0;
      isReadyOnlyFrontEnd: boolean;
      hasInActiveOrExcludedFromAutomationWorkloadsSelected: boolean;
    }) => boolean;
    handleOnClick: boolean;
    icon?: JSX.Element;
    renderRow?: () => JSX.Element;
  }

  const attachPolicyToWorkloadsInBulk = (policyName: string) => {
    const workloadsToUpdate =
      selectedRows
        ?.filter((row) => row.isPolicyForced !== true)
        ?.map((s) => {
          return {
            namespace: s.namespace,
            type: s.type,
            name: s.workloadName,
            id: s.id,
            rollbackPolicyName: s.policyName,
          };
        }) || [];

    const promises: Promise<void>[] = [];
    if (workloadsToUpdate?.length > 1000) {
      let i = 0;
      while (i * 1000 < workloadsToUpdate.length) {
        if (i == 0) {
          promises.push(attachPolicyInBulk(policyName, workloadsToUpdate.slice(i, 1000)));
        } else {
          const offset = i * 1000;
          promises.push(attachPolicyInBulk(policyName, workloadsToUpdate.slice(offset + 1, offset + 1000)));
        }
        i = i + 1;
      }
    } else {
      promises.push(attachPolicyInBulk(policyName, workloadsToUpdate));
    }

    Promise.all(promises).then(() => {
      //
    });
  };

  const restartWorkloadsBulk = (selectedRows?: components["schemas"]["UtilsWorkload"][]): void => {
    const workloads = selectedRows?.map((w) => {
      return { name: w.workloadName, type: w.type, namespace: w.namespace };
    });
    if (workloads !== undefined) {
      const chunks = SpliceIntoChunks<{ name: string; type: string; namespace: string }>(workloads, 1000);
      Promise.all(chunks.map(restartInBulk))
        .then(() => {
          //
        })
        .catch((reason) => console.error(reason));
    }
  };

  const restoreAllPolicyInBulk = (selectedRows?: components["schemas"]["UtilsWorkload"][]): void => {
    const workloads = selectedRows
      ?.filter((row) => row.isAutoForced !== true)
      ?.map((w) => {
        return { name: w.workloadName, type: w.type, namespace: w.namespace, id: w.id };
      });
    if (workloads !== undefined) {
      const chunks = SpliceIntoChunks<{ name: string; type: string; namespace: string; id: string }>(workloads, 1000);
      Promise.all(chunks.map(restorePolicyInBulk))
        .then(() => {
          //
        })
        .catch((reason) => console.error(reason));
    }
  };

  const workloadActionOptions: WorkloadOption[] = [
    {
      name: "Automate",
      callback: () => {
        automateWorkloads(true);
      },
      isDisabled: ({
        numberOfSelectedRows,
        isReadyOnlyFrontEnd,
        hasInActiveOrExcludedFromAutomationWorkloadsSelected,
      }) => {
        return (
          isReadyOnlyFrontEnd ||
          Number(numberOfSelectedRows) === 0 ||
          hasInActiveOrExcludedFromAutomationWorkloadsSelected
        );
      },
      handleOnClick: true,
      icon: <AutomationIcon />,
      renderRow: () => <AutomateMenuItemDiv />,
    },
    {
      name: "Un-Automate",
      callback: () => {
        automateWorkloads(false);
      },
      isDisabled: ({
        numberOfSelectedRows,
        isReadyOnlyFrontEnd,
        hasInActiveOrExcludedFromAutomationWorkloadsSelected,
      }) => {
        return (
          isReadyOnlyFrontEnd ||
          Number(numberOfSelectedRows) === 0 ||
          hasInActiveOrExcludedFromAutomationWorkloadsSelected
        );
      },
      handleOnClick: true,
      icon: <UnAutomateIcon />,
    },
    {
      name: (
        <SelectActionItem
          options={policies.map((policy) => policy.metadata?.name).filter((policy) => policy !== undefined) as string[]}
          onChange={attachPolicyToWorkloadsInBulk}
          defaultValue={defaultSelectedPolicyName}
          disabled={disabled}
          preSelectText="Attach policy to "
        />
      ),
      isDisabled: ({ numberOfSelectedRows, isReadyOnlyFrontEnd }) => {
        return isReadyOnlyFrontEnd || Number(numberOfSelectedRows) === 0;
      },
      handleOnClick: policyNameToWorkload !== "",
      icon: <PolicyIcon />,
    },
    {
      name: "Restore suggested policy",
      callback: restoreAllPolicyInBulk,
      isDisabled: ({ numberOfSelectedRows, isReadyOnlyFrontEnd }) => {
        return isReadyOnlyFrontEnd || Number(numberOfSelectedRows) === 0;
      },
      icon: <Restore />,
      handleOnClick: true,
    },
    {
      name: "Rollout",
      callback: restartWorkloadsBulk,
      isDisabled: ({
        numberOfSelectedRows,
        isReadyOnlyFrontEnd,
        hasInActiveOrExcludedFromAutomationWorkloadsSelected,
      }) => {
        return (
          isReadyOnlyFrontEnd ||
          Number(numberOfSelectedRows) === 0 ||
          hasInActiveOrExcludedFromAutomationWorkloadsSelected
        );
      },
      icon: <RestartAlt />,
      handleOnClick: true,
    },
  ];

  useEffect(() => {
    if (innerCurrentSearchTerm && innerCurrentSearchTerm !== currentSearchTerm) {
      setCurrentSearchTerm(innerCurrentSearchTerm);
    } else {
      setCurrentSearchTerm(undefined);
    }
  }, [innerCurrentSearchTerm]);

  useEffect(() => {
    setShowFilterArrow(
      !!activeFilterWrapperRef.current?.offsetHeight && activeFilterWrapperRef.current?.offsetHeight > 81
    );
  }, [
    searchTerms,
    currentSearchTerm,
    isAutomated,
    isUnAutomated,
    hasMinReplicasSavings,
    hasHpaAutomatedPolicy,
    hasHpaUnAutomatedPolicy,
    hasHpa,
    misconfiguredHpa,
    hpaOptimizationTypeParams,
    isHpaOptimizationTypeExclude,
    logicalHpaOptimizationType,
    overProvisionedFilter,
    underProvisionedFilter,
    namespaces,
    namespaceAnnotationLogicalOperator,
    isNamespacesExclude,
    selectedLabelNames,
    labelsParamsExclude,
    logicalLabel,
    selectedAnnotationNames,
    annotationsParamsExclude,
    logicalAnnotation,
    types,
    isTypesExclude,
    priorityClassNames,
    isPriorityClassNamesExclude,
    policy,
    isPolicyExclude,
    workloadErrors,
    workloadErrorsExclude,
    logicalWorkloadErrors,
    availableSavingsFilter,
    wastedResourcesParam,
    wastedResourcesExclude,
    logicalWastedResources,
    updateModeParams,
    isUpdateModeExclude,
    namespaceAnnotationParam,
    isNamespaceAnnotationExclude,
    isCappingStatusExclude,
    selectedCappingStatuses,
    logicalCappingStatus,
    logicalNamespaceAnnotations,
    unevictableTypes,
    hasGPU,
  ]);

  useEffect(() => {
    setDisabled(selectedRows == undefined || selectedRows.length == 0 || numberOfRows == 0);
  }, [selectedRows]);

  const automateWorkloads = (automate: boolean) => {
    const selectedRowsWithoutForcedAutomation = selectedRows?.filter((row) => row.isAutoForced !== true);
    updateOverriddenWorkloads({
      ids: selectedRowsWithoutForcedAutomation?.map((s) => s.id) || [],
      props: {
        auto: automate,
      },
    });

    const workloadsToUpdate =
      selectedRowsWithoutForcedAutomation?.map((s) => {
        return {
          namespace: s.namespace,
          type: s.type,
          name: s.workloadName,
          auto: s.auto,
          id: s.id,
          isAutomationExcluded: s.isAutomationExcluded,
        };
      }) || [];

    const promises: Promise<void>[] = [];
    if (workloadsToUpdate?.length > 1000) {
      let i = 0;
      while (i * 1000 < workloadsToUpdate.length) {
        if (i == 0) {
          promises.push(updateAutoInBulk(automate, workloadsToUpdate.slice(i, 1000)));
        } else {
          const offset = i * 1000;
          promises.push(updateAutoInBulk(automate, workloadsToUpdate.slice(offset + 1, offset + 1000)));
        }
        i = i + 1;
      }
    } else {
      promises.push(updateAutoInBulk(automate, workloadsToUpdate));
    }
  };

  const postAction = () => {
    setSelectedRows([]);
    setDisabled(true);
    fetchWorkloads();
    setAnchorElForWorkloadActions(null);
    setAnchorElForFilter(null);
    setPolicyNameToWorkload("");
  };

  const [anchorElForWorkloadActions, setAnchorElForWorkloadActions] = React.useState<null | HTMLElement>(null);
  const [anchorElForFilter, setAnchorElForFilter] = React.useState<null | HTMLElement>(null);
  const [addFilter, setAddFilter] = React.useState<string>("");
  const [isFreeDist, setIsFreeDist] = useState<boolean>(true);

  const [filteredNamespace, setFilteredNamespace] = React.useState<string>("");
  const [filteredPriorityClass, setFilteredPriorityClass] = React.useState<string>("");
  const [filteredOptimizationGaps, setFilteredOptimizationGaps] = React.useState<string>("");
  const [filteredHpaOptimizationType, setFilteredOptimizationType] = React.useState<string>("");
  const [filteredLabel, setFilteredLabel] = React.useState<string>("");
  const [filteredAnnotation, setFilteredAnnotation] = React.useState<string>("");
  const [filterRefresh, setFilterRefresh] = React.useState<boolean>(false);
  const [filteredCappingStatus, setFilteredCappingStatus] = React.useState<string>("");

  const workloadActionsOpen = Boolean(anchorElForWorkloadActions);

  function filterDisabled() {
    return (
      !namespacesParams ||
      (namespacesParams && namespacesParams?.length === 0) ||
      (namespacesParams && namespacesParams?.length > MAX_NAMESPACES_FOR_BULK_ACTIONS)
    );
  }

  useEffect(() => {
    setIsFreeDist(IsFreeDistribution());
  }, []);

  const handleClickWorkloadAction = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElForWorkloadActions(event.currentTarget);
  };

  useEffect(() => {
    const namespaceFilterValues: string[] = [];
    const priorityClassFilterValues: string[] = [];
    const labelFilterValues: string[] = [];
    const annotationFilterValues: string[] = [];
    const optimizationGapsValues: string[] = [];
    const cappedStatusValues: string[] = [];

    filters.map((filter) => {
      if (filter.type === "namespace") {
        namespaceFilterValues.push(filter.valueName);
      } else if (filter.type === "priorityClass") {
        priorityClassFilterValues.push(filter.valueName);
      } else if (filter.type === "labels") {
        labelFilterValues.push(filter.valueName);
      } else if (filter.type === "annotations") {
        annotationFilterValues.push(filter.valueName);
      } else if (filter.type === "optimizationGaps") {
        optimizationGapsValues.push(filter.valueName);
      } else if (filter.type === "cappingStatus") {
        cappedStatusValues.push(filter.valueName);
      }
    });

    const filtersToHandle: WorkloadTableFilterToHandle[] = [];

    if (namespaceFilterValues.length > 0) {
      filtersToHandle.push({
        type: "namespace",
        namespaces: namespaceFilterValues,
        labels: [],
        annotations: [],
        priorityClass: [],
        optimizationGaps: [],
        cappedStatuses: [],
      });
    }

    if (priorityClassFilterValues.length > 0) {
      filtersToHandle.push({
        type: "priorityClass",
        priorityClass: priorityClassFilterValues,
        namespaces: [],
        annotations: [],
        labels: [],
        optimizationGaps: [],
        cappedStatuses: [],
      });
    }

    if (labelFilterValues.length > 0) {
      filtersToHandle.push({
        type: "labels",
        namespaces: [],
        labels: labelFilterValues,
        annotations: [],
        priorityClass: [],
        optimizationGaps: [],
        cappedStatuses: [],
      });
    }

    if (annotationFilterValues.length > 0) {
      filtersToHandle.push({
        type: "annotations",
        namespaces: [],
        labels: [],
        annotations: annotationFilterValues,
        priorityClass: [],
        optimizationGaps: [],
        cappedStatuses: [],
      });
    }

    if (optimizationGapsValues.length > 0) {
      filtersToHandle.push({
        type: "annotations",
        namespaces: [],
        labels: [],
        annotations: [],
        priorityClass: [],
        optimizationGaps: optimizationGapsValues,
        cappedStatuses: [],
      });
    }

    if (cappedStatusValues.length > 0) {
      filtersToHandle.push({
        type: "cappingStatus",
        namespaces: [],
        labels: [],
        annotations: [],
        priorityClass: [],
        optimizationGaps: [],
        cappedStatuses: cappedStatusValues,
      });
    }
  }, [filterRefresh]);

  function buildFilterDisplayed(filter: WorkloadTableFilter) {
    if (filter.valueName === "") {
      return filter.type;
    }

    if (filter.type === "namespace") {
      return "ns: " + filter.valueName;
    }

    if (filter.type === "labels") {
      return "l: " + filter.valueName;
    }

    if (filter.type === "priorityClass") {
      return "pc: " + filter.valueName;
    }

    if (filter.type === "annotations") {
      return "a: " + filter.valueName;
    }

    if (filter.type === "cappingStatus") {
      return "cs: " + filter.valueName;
    }

    return "";
  }

  function filterLabelAndNamespace() {
    const addFilters = filters;

    const newFilter: WorkloadTableFilter = { type: addFilter, valueName: "" };
    if (addFilter === "") {
      return;
    }

    if (addFilter === "namespace") {
      newFilter.valueName = filteredNamespace;
    } else if (addFilter === "priorityClass") {
      newFilter.valueName = filteredPriorityClass;
    } else if (addFilter === "labels") {
      newFilter.valueName = filteredLabel;
    } else if (addFilter === "annotations") {
      newFilter.valueName = filteredAnnotation;
    } else if (addFilter === "optimizationGaps") {
      newFilter.valueName = filteredOptimizationGaps;
    } else if (addFilter === "cappingStatus") {
      newFilter.valueName = filteredCappingStatus;
    }

    const f = filters.find((f: WorkloadTableFilter) => addFilter == f.type && newFilter.valueName == f.valueName);
    if (f == undefined && filters.length < 5) {
      addFilters.push(newFilter);
      setFilters(addFilters);
      setFilterRefresh(!filterRefresh);
    }

    setAddFilter("");
    setAnchorElForFilter(null);
    setFilteredLabel("");
    setFilteredAnnotation("");
    setFilteredNamespace("");
  }

  useEffect(() => {
    filterLabelAndNamespace();
  }, [filteredNamespace, filteredPriorityClass, filteredOptimizationGaps, filteredCappingStatus]);

  // show limited filters --> start
  const windowSize = useWindowSize();

  const [totalToShow, setTotalToShow] = useState<number>(0);
  const [totalToHide, setTotalToHide] = useState<number>(0);
  const [showAll, setShowAll] = useState<boolean>(isHPAProduct);
  const [initialWidth, setInitialWidth] = useState<number[]>([]);

  const containerRef = useRef<HTMLDivElement>(null);
  const filtersRef = useRef<HTMLDivElement[]>([]);
  const refWidth = (index: number) => (initialWidth[index] ? initialWidth[index] + FILTER_GAP : FILTER_GAP);

  useEffect(() => {
    setShowAll(isHPAProduct);
  }, [isHPAProduct]);

  useEffect(() => {
    // set the initial width array
    if (filtersRef.current?.length > 0 && initialWidth.length === 0) {
      const initialWidthArray: number[] = [];
      filtersRef.current.map((filter) => {
        initialWidthArray.push(filter.offsetWidth + FILTER_GAP);
      });
      setInitialWidth(initialWidthArray);
    }
  }, [filtersRef.current?.length]);

  useLayoutEffect(() => {
    if (containerRef.current?.offsetWidth && initialWidth.length > 0 && !showAll) {
      let currentWidth = 0;
      let index = 0;
      while (currentWidth + refWidth(index) + 300 < containerRef.current?.offsetWidth && initialWidth[index]) {
        currentWidth += refWidth(index);
        index++;
      }

      setTotalToShow(index ?? 0);
      setTotalToHide(filtersRef.current?.length ? filtersRef.current?.length - 1 - (index ?? 0) : 0);
    }
  }, [windowSize.width, filtersRef.current?.length, containerRef.current?.offsetWidth, showAll]);

  const areNonNamespaceFiltersApplied =
    (searchTerms && searchTerms.length > 0) ||
    (policy && policy.length > 0) ||
    (priorityClassNamesParams && priorityClassNamesParams.length > 0) ||
    (optimizationGapsParams && optimizationGapsParams.length > 0) ||
    (selectedLabelNames && selectedLabelNames.length > 0) ||
    (selectedAnnotationNames && selectedAnnotationNames.length > 0) ||
    (typesParams && typesParams.length > 0) ||
    (workloadErrors && workloadErrors.length > 0) ||
    (updateModeParams && updateModeParams.length > 0) ||
    overProvisioned2 ||
    underProvisioned2 ||
    unevictableTypes ||
    availableSavingsFilter ||
    isAutomated ||
    isUnAutomated ||
    hasMinReplicasSavings ||
    hasHpaAutomatedPolicy ||
    hasHpaUnAutomatedPolicy ||
    hasHpa ||
    misconfiguredHpa ||
    (hpaOptimizationTypeParams && hpaOptimizationTypeParams.length > 0) ||
    outOfMemory ||
    (logicalNamespaceAnnotations && logicalNamespaceAnnotations.length > 0) ||
    (wastedResourcesParam && wastedResourcesParam.length > 0) ||
    hasGPU ||
    (selectedCappingStatuses && selectedCappingStatuses.length > 0);

  const areFiltersApplied =
    (namespacesParams && namespacesParams.length > 0) ||
    (namespaceAnnotationParam && namespaceAnnotationParam.length > 0) ||
    areNonNamespaceFiltersApplied;

  const handleSearch = () => {
    if (currentSearchTerm && (!searchTerms || !searchTerms.includes(currentSearchTerm))) {
      setSearchTerms((s) => [currentSearchTerm, ...(s || [])]);
      setCurrentSearchTerm(undefined);
      setInnerCurrentSearchTerm(undefined);
    }
  };

  let filterIndex = 1;

  const getAndIncrementFilterIndex = () => {
    return filterIndex++;
  };

  const showNamespacesMenu =
    !areNonNamespaceFiltersApplied &&
    selectedRows?.length === 0 &&
    ((namespacesParams && namespacesParams.length !== 0) ||
      (namespaceAnnotationParam && namespaceAnnotationParam.length !== 0));

  const noNamespaceOrWorkloadSelected =
    selectedRows?.length === 0 &&
    (!namespacesParams || namespacesParams?.length === 0) &&
    (!namespaceAnnotationParam || namespaceAnnotationParam?.length === 0);

  const hasInActiveOrExcludedFromAutomationWorkloadsSelected =
    selectedRows?.some((wl) => wl.isAutomationExcluded || !wl.isReadyRecommendation) || false;

  const numberOfSelectedWorkloadsExcludedFromAutomation =
    selectedRows?.filter((wl) => wl.isAutomationExcluded).length || 0;

  const numberOfSelectedIsAutoForcedWorkloads = selectedRows?.filter((wl) => wl.isAutoForced).length || 0;

  const numberOfSelectedForcedPolicyWorkloads = selectedRows?.filter((wl) => wl.isPolicyForced).length || 0;

  return (
    <div>
      <div className="mb-1 w-full flex items-start space-between relative">
        <div
          className="flex flex-wrap justify-start items-center min-h-8 w-full relative"
          style={{ gap: FILTER_GAP, rowGap: 14 }}
          ref={containerRef}
        >
          <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[130px]">
            <Input
              placeholder="search..."
              borderColor={SCALEOPS_COLORS.black}
              onChange={(e) => {
                const newValue = e.target.value.trim();
                setInnerCurrentSearchTerm(newValue);
              }}
              onKeyPress={(key) => {
                if (key.code === "Enter") {
                  handleSearch();
                }
              }}
              onBlur={handleSearch}
              value={innerCurrentSearchTerm ? innerCurrentSearchTerm : ""}
              dataTestId="overview-search-input"
            />
          </div>
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) && (
            <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[100.08px]">
              <CustomFilterChip
                label="automated"
                onClick={() => {
                  setIsAutomated((s) => setBooleanUrlParam(!s));
                }}
                selected={!!isAutomated}
                disabled={!!isUnAutomated}
              />
            </div>
          )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) && (
            <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[123.06px]">
              <CustomFilterChip
                label="un-automated"
                onClick={() => {
                  setIsUnAutomated((s) => setBooleanUrlParam(!s));
                }}
                selected={!!isUnAutomated}
                disabled={!!isAutomated}
              />
            </div>
          )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) && !isHPAProduct && (
            <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[154px]">
              <MultiSelectByQueryParams
                options={Object.values(OverProvisionedTypes).map((type) => type) as string[]}
                queryKey="overProvisionedFilter"
                name="over provisioned"
                hasIsExclude={false}
                hasSelectAllAndClearSelection={false}
                isSearchable={false}
              />
            </div>
          )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) && !isHPAProduct && (
            <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[164px]">
              <MultiSelectByQueryParams
                options={Object.values(UnderProvisionedTypes).map((type) => type) as string[]}
                queryKey="underProvisionedFilter"
                name="under provisioned"
                hasIsExclude={false}
                hasSelectAllAndClearSelection={false}
                isSearchable={false}
              />
            </div>
          )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) && (
            <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[132px]">
              <MultiSelectByQueryParams
                options={namespaces}
                queryKey="namespaces"
                tooltipPrefix={
                  isNamespacesExclude ? (
                    <>
                      namespaces <b>exclude: </b>
                    </>
                  ) : (
                    <>
                      namespaces <b>include: </b>
                    </>
                  )
                }
                hasVirtualizedList
              />
            </div>
          )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) && (
            <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[85px]">
              <KeyValueMultiSelect
                keysToValues={workloadsLabels}
                queryKey="labels"
                tooltipPrefix={
                  labelsParamsExclude ? (
                    <>
                      labels <b>exclude: </b>
                    </>
                  ) : (
                    <>
                      labels <b>include: </b>
                    </>
                  )
                }
                hasVirtualizedList
                hasLogicalAndOR
                logicalOperatorQueryKey="logicalLabel"
              />
            </div>
          )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) && !isHPAProduct && (
            <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[125px]">
              <KeyValueMultiSelect
                keysToValues={workloadsAnnotations}
                queryKey="annotations"
                tooltipPrefix={
                  annotationsParamsExclude ? (
                    <>
                      annotations <b>exclude: </b>
                    </>
                  ) : (
                    <>
                      annotations 1 <b>include: </b>
                    </>
                  )
                }
                hasVirtualizedList
                hasLogicalAndOR
                logicalOperatorQueryKey="logicalAnnotation"
                enableAddCustomValue
              />
            </div>
          )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) && !isHPAProduct && (
            <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[82px]">
              <MultiSelectByQueryParams
                options={types}
                queryKey="types"
                capitalize
                optionRenderFunction={(option) => {
                  const displayValue = workloadTypes[option as keyof typeof workloadTypes] ?? option;

                  return (
                    <MenuItem value={option} key={`${option}-multi-select-option`}>
                      <Checkbox checked={!!typesParams && typesParams.includes(option)} />
                      <ListItemText primary={displayValue} />
                    </MenuItem>
                  );
                }}
                tooltipPrefix={
                  isTypesExclude ? (
                    <>
                      types <b>exclude: </b>
                    </>
                  ) : (
                    <>
                      types <b>include: </b>
                    </>
                  )
                }
              />
            </div>
          )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) && !isHPAProduct && (
            <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[128px]">
              <MultiSelectByQueryParams
                options={priorityClassNames}
                queryKey="priorityClassNames"
                name="priority class"
                tooltipPrefix={
                  isPriorityClassNamesExclude ? (
                    <>
                      priority classes <b>exclude: </b>
                    </>
                  ) : (
                    <>
                      priority classes <b>include: </b>
                    </>
                  )
                }
              />
            </div>
          )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll || isHPAProduct) && (
            <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[85px]">
              <MultiSelectByQueryParams
                options={
                  policies
                    .map((policy) => policy.metadata?.name)
                    .filter((policy) => policy !== undefined)
                    .filter((policy) => hpaOptimizationEnabled || policy != PolicyType.ProductionHpa) as string[]
                }
                queryKey="policy"
                tooltipPrefix={
                  isPolicyExclude ? (
                    <>
                      policies <b>exclude: </b>
                    </>
                  ) : (
                    <>
                      policies <b>include: </b>
                    </>
                  )
                }
              />
            </div>
          )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) && !isHPAProduct && (
            <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[106px]">
              <MultiSelectByQueryParams
                options={Object.keys(WorkloadWarningsTypes).map((type) => type)}
                optionRenderFunction={(option) => (
                  <MenuItem value={option} key={`${option}-multi-select-option`}>
                    <Checkbox checked={!!workloadErrors && workloadErrors.includes(option)} />
                    <ListItemText primary={WorkloadWarningsTypes[option as keyof typeof WorkloadWarningsTypes]} />
                  </MenuItem>
                )}
                queryKey="workloadErrors"
                name="warnings"
                tooltipPrefix={
                  workloadErrorsExclude ? (
                    <>
                      warning <b>exclude: </b>
                    </>
                  ) : (
                    <>
                      warning <b>include: </b>
                    </>
                  )
                }
                hasLogicalAndOR
                logicalOperatorQueryKey="logicalWorkloadErrors"
              />
            </div>
          )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll || isHPAProduct) && (
            <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[76px]">
              <CustomFilterChip
                label="savings"
                onClick={() => {
                  setAvailableSavingsFilter(availableSavingsFilter ? undefined : AvailableSavingsFilter.Positive);
                }}
                selected={availableSavingsFilter === AvailableSavingsFilter.Positive}
              />
            </div>
          )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) && !isHPAProduct && (
            <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[161px]">
              <MultiSelectWastedResourcesByQueryParams />
            </div>
          )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) && !isHPAProduct && (
            <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[145px]">
              <MultiSelectByQueryParams
                options={Object.keys(RolloutStrategyTypes).map((type) => type)}
                optionRenderFunction={(option) => (
                  <MenuItem value={option} key={`${option}-multi-select-option`}>
                    <Checkbox checked={!!updateModeParams && updateModeParams.includes(option)} />
                    <ListItemText primary={RolloutStrategyTypes[option as keyof typeof RolloutStrategyTypes]} />
                  </MenuItem>
                )}
                queryKey="updateMode"
                name="rollout strategy"
                logicalOperatorQueryKey="logicalUpdateMode"
                tooltipPrefix={
                  isUpdateModeExclude ? (
                    <>
                      updateModes <b>exclude: </b>
                    </>
                  ) : (
                    <>
                      updateModes <b>include: </b>
                    </>
                  )
                }
                hasVirtualizedList
              />
            </div>
          )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) && !isHPAProduct && (
            <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[123px]">
              <MultiSelectByQueryParams
                options={["unevictable", "notHealthy"]}
                optionRenderFunction={(option) => (
                  <MenuItem value={option} key={`${option}-multi-select-option`} className="min-w-[250px]">
                    <Checkbox checked={!!unevictableTypes && unevictableTypes.includes(option)} />
                    <ListItemText primary={UnevictableTypes[option as keyof typeof UnevictableTypes]} />
                  </MenuItem>
                )}
                queryKey="unevictableTypes"
                name="unevictable"
                hasIsExclude={false}
                isSearchable={false}
              />
            </div>
          )}
          {enableFilterByUrlParam(FilterByUrlParam.ENABLE_OPTIMIZATION_GAPS_FILTER) &&
            (totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) &&
            !isHPAProduct && (
              <SnapshotWrapper wrappedType="filter">
                <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[163px]">
                  <MultiSelectByQueryParams
                    options={optimizationGaps}
                    optionRenderFunction={camelCaseToSnakeSpaceOptionRenderFunction}
                    queryKey="optimizationGaps"
                    name="optimization gaps"
                    tooltipPrefix={
                      isOptimizationGapsExclude ? (
                        <>
                          optimization gap <b>exclude: </b>
                        </>
                      ) : (
                        <>
                          optimization gap <b>include: </b>
                        </>
                      )
                    }
                  />
                </div>
              </SnapshotWrapper>
            )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) && !isHPAProduct && (
            <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[53px]">
              <CustomFilterChip
                label="GPU"
                onClick={() => {
                  setHasGPU((s) => setBooleanUrlParam(!s));
                }}
                selected={!!hasGPU}
              />
            </div>
          )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) && !isHPAProduct && (
            <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[53px]">
              <CustomFilterChip
                label="HPA"
                onClick={() => {
                  setHasHpa((s) => setBooleanUrlParam(!s));
                }}
                selected={!!hasHpa}
              />
            </div>
          )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) && !isHPAProduct && (
            <SnapshotWrapper wrappedType="filter">
              <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-fit">
                <CustomFilterChip
                  label="Misconfigured HPAs"
                  onClick={() => {
                    setMisconfiguredHpa((s) => setBooleanUrlParam(!s));
                  }}
                  selected={!!misconfiguredHpa}
                />
              </div>
            </SnapshotWrapper>
          )}
          {enableFilterByUrlParam(FilterByUrlParam.ENABLE_HPA_RECOMMENDATION) &&
            (totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) &&
            !isHPAProduct && (
              <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[170px]">
                <CustomFilterChip
                  label="HPA automated policy"
                  onClick={() => {
                    setHasHpaAutomatedPolicy((s) => setBooleanUrlParam(!s));
                  }}
                  selected={!!hasHpaAutomatedPolicy}
                />
              </div>
            )}
          {enableFilterByUrlParam(FilterByUrlParam.ENABLE_HPA_RECOMMENDATION) &&
            (totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) &&
            !isHPAProduct && (
              <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[190px]">
                <CustomFilterChip
                  label="HPA un-automated policy"
                  onClick={() => {
                    setHasHpaUnAutomatedPolicy((s) => setBooleanUrlParam(!s));
                  }}
                  selected={!!hasHpaUnAutomatedPolicy}
                />
              </div>
            )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) && isHPAProduct && (
            <SnapshotWrapper wrappedType="filter" inactive={hpaOptimizationEnabled}>
              <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[122px]">
                <MultiSelectByQueryParams
                  options={Object.keys(HpaOptimizationTypes)}
                  optionRenderFunction={hpaOptimizationTypeRenderFunction}
                  queryKey="hpaOptimizationType"
                  name={"Prediction status"}
                  hasIsExclude={false}
                  hasSelectAllAndClearSelection={false}
                  isSearchable={false}
                  capitalize
                />
              </div>
            </SnapshotWrapper>
          )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) && !isHPAProduct && (
            <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[207px]">
              <MultiSelectByQueryParams
                name={"namespace annotations"}
                options={namespaceAnnotations}
                queryKey="namespaceAnnotation"
                tooltipPrefix={
                  isNamespaceAnnotationExclude ? (
                    <>
                      namespace annotations <b>exclude: </b>
                    </>
                  ) : (
                    <>
                      namespace annotations <b>include: </b>
                    </>
                  )
                }
                hasVirtualizedList
                hasLogicalAndOR
                logicalOperatorQueryKey="logicalNamespaceAnnotation"
              />
            </div>
          )}
          {enableFilterByUrlParam(FilterByUrlParam.ENABLE_SPOT_FRIENDLY_FILTER) &&
            (totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) &&
            !isHPAProduct && (
              <SnapshotWrapper wrappedType="filter">
                <div ref={(el) => (filtersRef.current[21] = el as HTMLDivElement)} className="w-[110px]">
                  <CustomFilterChip
                    label="spot-friendly"
                    onClick={() => {
                      setIsSpotFriendly((s) => !s);
                      setIsSpotUnfriendly(undefined);
                    }}
                    selected={!!isSpotFriendly}
                    disabled={!!isSpotUnfriendly}
                  />
                </div>
              </SnapshotWrapper>
            )}
          {enableFilterByUrlParam(FilterByUrlParam.ENABLE_SPOT_FRIENDLY_FILTER) &&
            (totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) &&
            !isHPAProduct && (
              <SnapshotWrapper wrappedType="filter">
                <div ref={(el) => (filtersRef.current[22] = el as HTMLDivElement)} className="w-[130px]">
                  <CustomFilterChip
                    label="spot-un-friendly"
                    onClick={() => {
                      setIsSpotUnfriendly((s) => setBooleanUrlParam(!s));
                      setIsSpotFriendly(undefined);
                    }}
                    disabled={!!isSpotFriendly}
                    selected={!!isSpotUnfriendly}
                  />
                </div>
              </SnapshotWrapper>
            )}
          {(totalToShow > getAndIncrementFilterIndex() || totalToShow === 0 || showAll) && !isHPAProduct && (
            <SnapshotWrapper wrappedType="filter">
              <div ref={(el) => getRef({ filtersRef, el: el as HTMLDivElement })} className="w-[160px]">
                <MultiSelectByQueryParams
                  name="capping statuses"
                  options={serializedCappedStatuses}
                  optionRenderFunction={(option) => (
                    <MenuItem value={option} key={`${option}-multi-select-option`} className="min-w-[250px]">
                      <Checkbox checked={!!selectedCappingStatuses && selectedCappingStatuses.includes(option)} />
                      <ListItemText primary={cappedStatusToDescription(JSON.parse(option) as CappedStatus)} />
                    </MenuItem>
                  )}
                  queryKey="cappingStatuses"
                  tooltipPrefix={
                    labelsParamsExclude ? (
                      <>
                        capping statuses <b>exclude: </b>
                      </>
                    ) : (
                      <>
                        capping statuses <b>include: </b>
                      </>
                    )
                  }
                  hasVirtualizedList
                  hasLogicalAndOR
                  logicalOperatorQueryKey="logicalCappingStatus"
                />
              </div>
            </SnapshotWrapper>
          )}
          {!isHPAProduct && (
            <>
              {totalToHide && !showAll ? (
                <div onClick={() => setShowAll(true)} className={showMoreOrLessClassName}>
                  show all (+{totalToHide})
                </div>
              ) : (
                <div
                  className={clsx(showMoreOrLessClassName, {
                    hidden: !totalToHide,
                  })}
                  onClick={() => setShowAll(false)}
                >
                  show less
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex gap-[8px] items-start justify-end w-fit">
          {(totalToShow > 1 || totalToShow === 0 || showAll) && (
            <div className="w-[85px]" ref={(el) => (filtersRef.current[1] = el as HTMLDivElement)}>
              <MultiSelect
                selected={selectedColumns}
                setSelected={setSelectedColumns}
                options={availableColumns}
                className="w-[85px]"
                customIcon={<CustomColumnsFilterButton isFiltered={selectedColumns.length > 0} />}
                hasSelectAllAndClearSelection={false}
              />
            </div>
          )}
          <Tooltip title={YOU_HAVE_SELECTED_NON_EDITABLE_TEXT} disabled={!hasNonEditableWorkloadsSelectedRows}>
            <div
              className={clsx("relative", {
                "animate-bounce temporary-bounce":
                  numberOfSelectedRows || namespacesParams?.length || namespaceAnnotationParam?.length,
              })}
            >
              {numberOfSelectedRows || namespacesParams?.length || namespaceAnnotationParam?.length ? (
                <div className="absolute top-[-8px] right-[-10px] rounded-full bg-primary-purpleBlue scaleopsShadow text-white text-[10px] px-2">
                  {numberOfSelectedRows ? (
                    numberOfSelectedRows
                  ) : (
                    <>
                      {namespacesParams?.length ? namespacesParams?.length : ""}
                      {namespacesParams?.length && namespaceAnnotationParam?.length ? "/" : ""}
                      {namespaceAnnotationParam?.length ? namespaceAnnotationParam?.length : ""}
                    </>
                  )}
                </div>
              ) : null}
              {HAS_NEW_ACTION_MENU ? (
                <div>
                  <BulkActions
                    selectedRows={selectedRows}
                    numberOfSelectedWorkloadsExcludedFromAutomation={numberOfSelectedWorkloadsExcludedFromAutomation}
                    policies={policies}
                    automateWorkloads={automateWorkloads}
                    attachPolicyToWorkloadsInBulk={attachPolicyToWorkloadsInBulk}
                    defaultSelectedPolicyName={defaultSelectedPolicyName}
                    restoreAllPolicyInBulk={restoreAllPolicyInBulk}
                    restartWorkloadsBulk={restartWorkloadsBulk}
                    areNonNamespaceFiltersApplied={!!areNonNamespaceFiltersApplied}
                    automateByFilter={automateByFilter}
                    attachPolicyToNamespace={attachPolicyToNamespace}
                    attachPolicyToCluster={attachPolicyToCluster}
                    restoreClusterPolicy={restoreClusterPolicy}
                    restoreNamespacesPolicy={restoreNamespacesPolicy}
                    numberOfSelectedIsAutoForcedWorkloads={numberOfSelectedIsAutoForcedWorkloads}
                    numberOfSelectedForcedPolicyWorkloads={numberOfSelectedForcedPolicyWorkloads}
                    scaleOpsProduct={scaleOpsProduct}
                  />
                </div>
              ) : (
                <Button
                  label={<span style={{ fontWeight: 700 }}>Actions</span>}
                  className={clsx("scaleopsShadow min-w-[100px]", {
                    "cursor-not-allowed": hasNonEditableWorkloadsSelectedRows,
                  })}
                  dataTestId="workload-actions-button"
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    handleClickWorkloadAction(event);
                  }}
                  variant="smallGreen"
                  disabled={hasNonEditableWorkloadsSelectedRows}
                />
              )}
            </div>
          </Tooltip>
          {isFreeDist && (
            <div style={{ float: "right" }}>
              <Tooltip title={"Bulk actions are limited to ScaleOps full version"}>
                <InfoOutlinedIcon
                  style={{
                    fontSize: "medium",
                    float: "right",
                    marginLeft: "2px",
                    marginBottom: "5px",
                    color: "lightgray",
                  }}
                />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <div
        className={clsx("flex flex-wrap gap-2 max-w-[100%] my-2 relative transition-all duration-500 ease-in-out", {
          "max-h-[100px] overflow-hidden": !showAllUsedFilterChips,
        })}
        ref={activeFilterWrapperRef}
      >
        {currentSearchTerm && (
          <CustomSelectedFilterChip
            label={`search: ${currentSearchTerm}`}
            onClick={() => {
              setCurrentSearchTerm(undefined);
              setSearchTerms((s) => s?.filter((t) => t !== currentSearchTerm));
            }}
            className="max-w-[250px] truncate bg-main-deepBlue text-white"
            clearIcon={<span className="text-white animate-pulse text-[13px]">...</span>}
          />
        )}
        {searchTerms?.map((term) => {
          if (term) {
            return (
              <CustomSelectedFilterChip
                label={`search: ${term}`}
                onClick={() => {
                  const termsToUpdate = searchTerms?.filter((t) => t !== term);
                  setSearchTerms(termsToUpdate);
                }}
                className="max-w-[250px] truncate"
              />
            );
          }
        })}
        {namespacesParams?.map((entity) => (
          <CustomSelectedFilterChip
            label={
              <>
                namespaces{isNamespacesExclude ? EXCLUDE_SIGN : INCLUDE_SIGN} {entity ?? ""}
              </>
            }
            onClick={() => {
              setNamespacesParams((s) => s?.filter((l) => l !== entity));
            }}
            className="max-w-[250px] truncate"
            tooltipContent={
              <>
                namespaces {isNamespacesExclude ? <b>exclude</b> : <b>include</b>} "{entity ?? ""}".
              </>
            }
            hasTooltip
          />
        ))}
        {namespaceAnnotationParam?.map((entity) => (
          <CustomSelectedFilterChip
            label={
              <>
                namespace annotation{isNamespaceAnnotationExclude ? EXCLUDE_SIGN : INCLUDE_SIGN} {entity ?? ""}
              </>
            }
            onClick={() => {
              setNamespaceAnnotationParam((s) => s?.filter((l) => l !== entity));
            }}
            className="max-w-[250px] truncate"
            tooltipContent={
              <>
                namespace annotations {isNamespaceAnnotationExclude ? <b>exclude</b> : <b>include</b>} "{entity ?? ""}".
              </>
            }
            hasTooltip
          />
        ))}
        {policy?.map((entity) => (
          <CustomSelectedFilterChip
            label={
              <>
                policy{isPolicyExclude ? EXCLUDE_SIGN : INCLUDE_SIGN} {entity ?? ""}
              </>
            }
            onClick={() => {
              setPolices((s) => s?.filter((l) => l !== entity));
            }}
            className="max-w-[250px] truncate"
            tooltipContent={
              <>
                policies {isPolicyExclude ? <b>exclude</b> : <b>include</b>} "{entity ?? ""}".
              </>
            }
            hasTooltip
          />
        ))}
        {typesParams?.map((entity) => (
          <CustomSelectedFilterChip
            label={
              <>
                types{isTypesExclude ? EXCLUDE_SIGN : INCLUDE_SIGN} {entity ?? ""}
              </>
            }
            onClick={() => {
              setTypesParams((s) => s?.filter((l) => l !== entity));
            }}
            tooltipContent={
              <>
                types {isTypesExclude ? <b>exclude</b> : <b>include</b>} "{entity ?? ""}".
              </>
            }
            hasTooltip
          />
        ))}
        {filters.map((filter) => (
          <CustomSelectedFilterChip
            label={buildFilterDisplayed(filter)}
            onClick={() => {
              const newFilters = filters;
              const index = newFilters.indexOf(filter, 0);
              if (index > -1) {
                newFilters.splice(index, 1);
              }

              setFilters(newFilters);
              setFilterRefresh(!filterRefresh);
            }}
          />
        ))}
        {priorityClassNamesParams?.map((entity) => (
          <CustomSelectedFilterChip
            label={
              <>
                priority classes{isPriorityClassNamesExclude ? EXCLUDE_SIGN : INCLUDE_SIGN} {entity ?? ""}
              </>
            }
            onClick={() => {
              setPriorityClassNamesParams((s) => s?.filter((l) => l !== entity));
            }}
            className="max-w-[250px] truncate"
            hasTooltip
            tooltipContent={
              <>
                priority classes {isPriorityClassNamesExclude ? <b>exclude</b> : <b>include</b>} "{entity ?? ""}".
              </>
            }
          />
        ))}
        {unevictableTypes?.map((entity) => (
          <CustomSelectedFilterChip
            label={<>unevictable: {UnevictableTypes[entity as keyof typeof UnevictableTypes] ?? ""}</>}
            onClick={() => {
              setUnevictableTypes((s) => s?.filter((l) => l !== entity));
            }}
            className="max-w-[250px] truncate"
            hasTooltip
            tooltipContent={<>unevictable "{entity ?? ""}".</>}
          />
        ))}
        {optimizationGapsParams?.map((entity) => (
          <CustomSelectedFilterChip
            label={
              <>
                optimization gaps{isOptimizationGapsExclude ? EXCLUDE_SIGN : INCLUDE_SIGN} {entity ?? ""}
              </>
            }
            onClick={() => {
              setOptimizationGapsParams((s) => s?.filter((l) => l !== entity));
            }}
            className="max-w-[250px] truncate"
            hasTooltip
            tooltipContent={
              <>
                optimization gaps {isOptimizationGapsExclude ? <b>exclude</b> : <b>include</b>} "{entity ?? ""}".
              </>
            }
          />
        ))}
        {hpaOptimizationTypeParams?.map((entity) => (
          <CustomSelectedFilterChip
            label={
              <>
                Prediction status {isHpaOptimizationTypeExclude ? EXCLUDE_SIGN : INCLUDE_SIGN}{" "}
                {HpaOptimizationTypes[entity as keyof typeof HpaOptimizationTypes] ?? ""}
              </>
            }
            onClick={() => {
              setHpaOptimizationTypeParams((s) => s?.filter((l) => l !== entity));
            }}
            className="max-w-[250px] truncate"
            hasTooltip
            tooltipContent={
              <>
                Prediction status {isHpaOptimizationTypeExclude ? <b>exclude</b> : <b>include</b>} "
                {HpaOptimizationTypes[entity as keyof typeof HpaOptimizationTypes] ?? ""}".
              </>
            }
          />
        ))}
        {overProvisionedFilter?.includes(OverProvisionedTypes.overProvisionedCPU) && (
          <CustomSelectedFilterChip
            label="cpu over provisioned"
            onClick={() => {
              setCpuOverProvisionedParam(undefined);
              setOverProvisionedFilter(
                overProvisionedFilter?.filter((f) => f !== OverProvisionedTypes.overProvisionedCPU)
              );
            }}
          />
        )}
        {underProvisionedFilter?.includes(UnderProvisionedTypes.underProvisionedCpu) && (
          <CustomSelectedFilterChip
            label="cpu under provisioned"
            onClick={() => {
              setCpuUnderProvisionedParam(undefined);
              setUnderProvisionedFilter(
                underProvisionedFilter?.filter((f) => f !== UnderProvisionedTypes.underProvisionedCpu)
              );
            }}
          />
        )}
        {overProvisionedFilter?.includes(OverProvisionedTypes.overProvisionedMemory) && (
          <CustomSelectedFilterChip
            label="memory over provisioned"
            onClick={() => {
              setMemoryOverProvisionedParam(undefined);
              setOverProvisionedFilter(
                overProvisionedFilter?.filter((f) => f !== OverProvisionedTypes.overProvisionedMemory)
              );
            }}
          />
        )}
        {underProvisionedFilter?.includes(UnderProvisionedTypes.underProvisionedMemory) && (
          <CustomSelectedFilterChip
            label="memory under provisioned"
            onClick={() => {
              setMemoryUnderProvisionedParam(undefined);
              setUnderProvisionedFilter(
                underProvisionedFilter?.filter((f) => f !== UnderProvisionedTypes.underProvisionedMemory)
              );
            }}
          />
        )}
        {!!availableSavingsFilter && (
          <CustomSelectedFilterChip label="savings" onClick={() => setAvailableSavingsFilter(undefined)} />
        )}
        {outOfMemory && <CustomSelectedFilterChip label="out of memory" onClick={() => setOutOfMemory(undefined)} />}
        {isAutomated && <CustomSelectedFilterChip label="automated" onClick={() => setIsAutomated(undefined)} />}
        {isUnAutomated && <CustomSelectedFilterChip label="un-automated" onClick={() => setIsUnAutomated(undefined)} />}
        {hasGPU && <CustomSelectedFilterChip label="GPU" onClick={() => setHasGPU(undefined)} />}
        {hasHpa && <CustomSelectedFilterChip label="HPA" onClick={() => setHasHpa(undefined)} />}
        {misconfiguredHpa && (
          <CustomSelectedFilterChip label="Overridden HPA" onClick={() => setMisconfiguredHpa(undefined)} />
        )}
        {hasMinReplicasSavings && (
          <CustomSelectedFilterChip label="HPA savings" onClick={() => setHasMinReplicasSavings(undefined)} />
        )}
        {hasHpaAutomatedPolicy && (
          <CustomSelectedFilterChip label="HPA automated policy" onClick={() => setHasHpaAutomatedPolicy(undefined)} />
        )}
        {hasHpaUnAutomatedPolicy && (
          <CustomSelectedFilterChip
            label="HPA un-automated policy"
            onClick={() => setHasHpaUnAutomatedPolicy(undefined)}
          />
        )}
        {selectedLabelNames?.map((labelName) => {
          let label = labelName;
          let labelPrefix = "labels";

          if (labelName?.startsWith(`<${KeyValueOptions.Key}>`)) {
            label = labelName.replace(`<${KeyValueOptions.Key}>`, "");
            labelPrefix = "labels keys";
          } else if (labelName?.startsWith(`<${KeyValueOptions.Value}>`)) {
            label = labelName.replace(`<${KeyValueOptions.Value}>`, "");
            labelPrefix = "labels values";
          }

          return (
            <CustomSelectedFilterChip
              label={
                <>
                  {labelPrefix}
                  {labelsParamsExclude ? EXCLUDE_SIGN : INCLUDE_SIGN} {label ?? ""}
                </>
              }
              onClick={() => {
                setSelectedLabelNames((s) => s?.filter((l) => l !== labelName));
              }}
              className="max-w-[200px]"
              hasTooltip
              tooltipContent={
                <>
                  {labelPrefix} {labelsParamsExclude ? <b>exclude</b> : <b>include</b>} "{label ?? ""}".
                </>
              }
            />
          );
        })}
        {selectedAnnotationNames?.map((annotationName) => {
          let annotationLabel = annotationName;
          let annotationsPrefix = "annotations";

          if (annotationName?.startsWith(`<${KeyValueOptions.Key}>`)) {
            annotationLabel = annotationName.replace(`<${KeyValueOptions.Key}>`, "");
            annotationsPrefix = "annotations keys";
          } else if (annotationName?.startsWith(`<${KeyValueOptions.Value}>`)) {
            annotationLabel = annotationName.replace(`<${KeyValueOptions.Value}>`, "");
            annotationsPrefix = "annotations values";
          }

          return (
            <CustomSelectedFilterChip
              label={
                <>
                  {annotationsPrefix}
                  {annotationsParamsExclude ? EXCLUDE_SIGN : INCLUDE_SIGN} {annotationLabel ?? ""}
                </>
              }
              onClick={() => {
                setSelectedAnnotationNames((s) => s?.filter((a) => a !== annotationName));
              }}
              className="max-w-[200px]"
              hasTooltip
              tooltipContent={
                <>
                  {annotationsPrefix} {annotationsParamsExclude ? <b>exclude</b> : <b>include</b>} "
                  {annotationLabel ?? ""}".
                </>
              }
            />
          );
        })}
        {workloadErrors?.map((workloadError) => {
          return (
            <CustomSelectedFilterChip
              label={
                <>
                  warning{workloadErrorsExclude ? EXCLUDE_SIGN : INCLUDE_SIGN}{" "}
                  {WorkloadWarningsTypes[workloadError as keyof typeof WorkloadWarningsTypes] ?? ""}.
                </>
              }
              onClick={() => {
                setWorkloadErrors((s) => s?.filter((w) => w !== workloadError));
              }}
              className="max-w-[200px]"
              hasTooltip
              tooltipContent={
                <>
                  warning {workloadErrorsExclude ? <b>exclude</b> : <b>include</b>} "
                  {WorkloadWarningsTypes[workloadError as keyof typeof WorkloadWarningsTypes]}".
                </>
              }
            />
          );
        })}
        {wastedResourcesParam &&
          wastedResourcesParam.map((wastedResource) => {
            const contentToDisplay = (
              <>
                wasted resources{wastedResourcesExclude ? EXCLUDE_SIGN : INCLUDE_SIGN}{" "}
                {getWastedResourceDisplayValue(String(wastedResource))}
              </>
            );

            return (
              <CustomSelectedFilterChip
                label={contentToDisplay}
                onClick={() => {
                  setWastedResourcesParam((s) => s?.filter((w) => w !== wastedResource));
                }}
                className="max-w-[200px]"
                hasTooltip
                tooltipContent={contentToDisplay}
              />
            );
          })}
        {updateModeParams?.map((updateMode) => {
          const rolloutStrategy = RolloutStrategyTypes[updateMode as keyof typeof RolloutStrategyTypes];
          return (
            <CustomSelectedFilterChip
              label={
                <>
                  rollout strategy{isUpdateModeExclude ? EXCLUDE_SIGN : INCLUDE_SIGN} {rolloutStrategy}
                </>
              }
              onClick={() => {
                setUpdateModeParams((s) => s?.filter((w) => w !== updateMode));
              }}
              className="max-w-[200px]"
              hasTooltip
              tooltipContent={
                <>
                  rollout strategy {isUpdateModeExclude ? <b>exclude</b> : <b>include</b>} "{rolloutStrategy}".
                </>
              }
            />
          );
        })}
        {selectedCappingStatuses?.map((cappingStatus) => {
          if (!cappingStatus) {
            return null;
          }

          const description = cappedStatusToDescription(JSON.parse(cappingStatus) as CappedStatus);
          return (
            <CustomSelectedFilterChip
              label={
                <>
                  capping status{isCappingStatusExclude ? EXCLUDE_SIGN : INCLUDE_SIGN} {description}
                </>
              }
              onClick={() => {
                setSelectedCappingStatuses((s) => s?.filter((w) => w !== cappingStatus));
              }}
              className="max-w-[200px]"
              hasTooltip
              tooltipContent={
                <>
                  capping status {isUpdateModeExclude ? <b>exclude</b> : <b>include</b>} "{description}".
                </>
              }
            />
          );
        })}
        <div
          className={clsx(
            "bottom-0 w-full h-[20px] bg-gradient-to-t from-white to-transparent flex justify-center items-end hover:cursor-pointer",
            {
              absolute: !showAllUsedFilterChips,
              hidden: !showFilterArrow,
            }
          )}
        >
          <DownIcon
            fill={SCALEOPS_COLORS.background.chipActive}
            className={clsx("", {
              "transform rotate-180": showAllUsedFilterChips, // "animate-bounce": !showAllUsedFilterChips,
            })}
            onClick={() => {
              setShowAllUsedFilterChips(!showAllUsedFilterChips);
            }}
            width={20}
            height={20}
          />
        </div>
      </div>
      {areFiltersApplied && (
        <div
          className={clsx("flex items-end mb-4", {
            "mt-5": showFilterArrow,
          })}
        >
          <Typography
            variant="caption"
            onClick={() => {
              clearWorkloadsOverviewFilters();
            }}
            className="hover:cursor-pointer hover:text-guideline-lessDarkBlue bg-white hover:bg-guideline-lightGray px-2 py-1 border-[0.5px] border-black hover:border-border rounded-lg flex items-center justify-center gap-1"
          >
            <UnFilterIcon width={15} height={15} />
            <p>Clear all filters</p>
          </Typography>
        </div>
      )}
      {HAS_NEW_WORKLOADS_FILTERS && <WorkloadsFilters />}
      <Menu
        anchorEl={anchorElForFilter}
        open={addFilter !== ""}
        style={{ width: "1000px", height: "10000px" }}
        onClose={() => {
          setAddFilter("");
          setAnchorElForFilter(null);
        }}
      >
        <MenuList style={{ width: "300px" }}>
          {(addFilter === "namespace" ||
            addFilter === "labels" ||
            addFilter == "priorityClass" ||
            addFilter == "annotations" ||
            addFilter == "hpaOptimizationType" ||
            addFilter == "optimizationGap" ||
            addFilter == "cappingStatus") && (
            <div>
              {<MenuItem>The {addFilter} Includes:</MenuItem>}
              {addFilter === "namespace" && (
                <MenuItem>
                  <FormControl fullWidth>
                    <InputLabel id="namespace-filter-label">Namespace</InputLabel>
                    <Select
                      labelId="namespace-filter-label"
                      id="namespace-filter"
                      value={filteredNamespace}
                      label="namespace"
                      onChange={(event) => {
                        setFilteredNamespace(event.target.value);
                      }}
                    >
                      {namespaces.map((namespaceToFiltered) => (
                        <MenuItem value={namespaceToFiltered}>{namespaceToFiltered}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MenuItem>
              )}
              {addFilter === "labels" && (
                <MenuItem style={{ width: "300px" }}>
                  <TextField
                    label={"label"}
                    style={{ width: "300px" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => {
                      setFilteredLabel(event.target.value);
                    }}
                  ></TextField>
                </MenuItem>
              )}
              {addFilter === "annotations" && (
                <MenuItem style={{ width: "300px" }}>
                  <TextField
                    label={"annotation"}
                    style={{ width: "300px" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => {
                      setFilteredAnnotation(event.target.value);
                    }}
                  ></TextField>
                </MenuItem>
              )}
              {addFilter === "priorityClass" && (
                <MenuItem>
                  <FormControl fullWidth>
                    <InputLabel id="priority-class-filter-label">Priority Class</InputLabel>
                    <Select
                      labelId="priority-class-filter-label"
                      id="priority-class-filter"
                      value={filteredPriorityClass}
                      label="Priority class"
                      onChange={(event) => {
                        setFilteredPriorityClass(event.target.value);
                      }}
                    >
                      {priorityClassNames.map((priorityClass) => (
                        <MenuItem value={priorityClass}>{priorityClass}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MenuItem>
              )}
              {addFilter === "optimizationGap" && (
                <MenuItem>
                  <FormControl fullWidth>
                    <InputLabel id="optimization-gap-filter-label">Priority Class</InputLabel>
                    <Select
                      labelId="optimization-gap-filter-label"
                      id="optimization-gap-filter"
                      value={filteredOptimizationGaps}
                      label="Optimization Gap"
                      onChange={(event) => {
                        setFilteredOptimizationGaps(event.target.value);
                      }}
                    >
                      {optimizationGaps.map((optimizationGap) => (
                        <MenuItem value={optimizationGap}>{optimizationGap}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MenuItem>
              )}
              {addFilter === "hpaOptimizationType" && (
                <MenuItem>
                  <FormControl fullWidth>
                    <InputLabel id="hpa-optimization-type-filter-label">HPA Optimization Type</InputLabel>
                    <Select
                      labelId="hpa-optimization-type-filter-label"
                      id="hpa-optimization-type-filter"
                      value={filteredHpaOptimizationType}
                      label="HPA Optimization Type"
                      onChange={(event) => {
                        setFilteredOptimizationType(event.target.value);
                      }}
                    >
                      {Object.keys(HpaOptimizationTypes).map((predictable) => (
                        <MenuItem value={predictable}>{predictable}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MenuItem>
              )}
              {addFilter === "cappingStatus" && (
                <MenuItem>
                  <FormControl fullWidth>
                    <InputLabel id="capping-status-filter-label">Capping Status</InputLabel>
                    <Select
                      labelId="capping-status-filter-label"
                      id="capping-status-filter"
                      value={filteredCappingStatus}
                      label="Capping Status"
                      onChange={(event) => {
                        setFilteredCappingStatus(event.target.value);
                      }}
                    >
                      {serializedCappedStatuses.map((serializedCappedStatus) => (
                        <MenuItem value={serializedCappedStatus}>
                          {cappedStatusToDescription(JSON.parse(serializedCappedStatus) as CappedStatus)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MenuItem>
              )}
            </div>
          )}
        </MenuList>
      </Menu>
      <Box
        sx={{
          float: "right",
          paddingTop: "10px",
          paddingBottom: 0,
          paddingRight: "10px",
        }}
      >
        <Menu
          id="long-menu"
          anchorEl={anchorElForWorkloadActions}
          open={workloadActionsOpen}
          onClose={() => setAnchorElForWorkloadActions(null)}
        >
          <div className="py-4 px-1">
            {isReadyOnlyFrontEnd && <YouHaveReadOnlyAccess className="mb-2 ml-4" chipDesign />}
            {noNamespaceOrWorkloadSelected && (
              <div className="mb-4 mx-4 p-4 border border-border rounded-lg">
                <div>
                  {!isReadyOnlyFrontEnd && disabled && filterDisabled() && !numberOfSelectedRows ? (
                    <Typography variant="body2" className="max-w-[320px] text-text-lightBlack">
                      Select <b className="text-primary-purpleBlue">workloads</b> or{" "}
                      <b className="text-primary-purpleBlue">namespace</b> and take action.
                      <div className="text-[10px] mt-[12px] gap-2 flex flex-col">
                        <CustomListItem
                          content={
                            <>
                              <b>Namespace</b> actions are applied to all <b>current and newly created workloads.</b>
                            </>
                          }
                        />
                        <CustomListItem
                          content={
                            <>
                              <b>Workloads</b> actions applied to <b>all selected workloads.</b>
                            </>
                          }
                        />
                      </div>
                    </Typography>
                  ) : (
                    <div className="flex gap-3 justify-start items-center">
                      <CircleIconWrapper
                        className="text-white bg-primary-purpleBlue"
                        icon={<BulkIcon width={14} height={14} />}
                      />
                      <Typography variant="body2" className="max-w-[350px] h-fit">
                        <b className="text-primary-purpleBlue scaleopsTextShadow">
                          {numberOfSelectedRows}{" "}
                          {pluralize("workload", selectedRows?.length ? selectedRows?.length : 1)} selected
                        </b>
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            )}
            {!noNamespaceOrWorkloadSelected && !showNamespacesMenu && (
              <div className="mb-4 mx-4 p-4 border border-border rounded-lg">
                <div>
                  {!isReadyOnlyFrontEnd && disabled && filterDisabled() && !numberOfSelectedRows ? (
                    <Typography variant="body2" className="max-w-[290px]">
                      Select <b className="text-primary-purpleBlue">one or more workloads</b> in order to perform an
                      action.
                    </Typography>
                  ) : (
                    <div className="flex gap-3 justify-start items-center">
                      <CircleIconWrapper
                        className="text-white bg-primary-purpleBlue"
                        icon={<BulkIcon width={14} height={14} />}
                      />
                      <Typography variant="body2" className="max-w-[350px] h-fit">
                        <b className="text-primary-purpleBlue scaleopsTextShadow">
                          {numberOfSelectedRows}{" "}
                          {pluralize("workload", selectedRows?.length ? selectedRows?.length : 1)} selected
                        </b>
                      </Typography>
                    </div>
                  )}
                  {hasInActiveOrExcludedFromAutomationWorkloadsSelected && (
                    <div className="text-[10px] mt-[12px] gap-2 flex flex-col">
                      <CustomListItem
                        iconType="info"
                        content={
                          <>
                            <b>Note:</b> Some of the selected workloads are <b>excluded from automation</b>.
                          </>
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {!showNamespacesMenu &&
              workloadActionOptions.map((option) => (
                <MenuItem
                  data-testid={
                    option?.name ? String(option?.name)?.replaceAll(" ", "-").toLocaleLowerCase() : undefined
                  }
                  key={option.name.toString()}
                  onClick={() => {
                    if (option.handleOnClick) {
                      option.callback && option.callback(selectedRows);
                      postAction();
                    }
                  }}
                  disabled={option.isDisabled({
                    numberOfSelectedRows,
                    isReadyOnlyFrontEnd,
                    hasInActiveOrExcludedFromAutomationWorkloadsSelected: false,
                  })}
                >
                  {option?.renderRow ? (
                    option?.renderRow()
                  ) : (
                    <div className="flex gap-2 justify-center items-center h-[28px] pl-[22px]">
                      {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                      <Typography variant="body2">{option.name}</Typography>
                    </div>
                  )}
                </MenuItem>
              ))}
            {showNamespacesMenu && (
              <NamespacesActionsMenu
                automateByFilter={automateByFilter}
                policies={policies}
                attachPolicyToNamespace={attachPolicyToNamespace}
                disable={!!areNonNamespaceFiltersApplied || canEditAllWorkloads === false}
              />
            )}
          </div>
        </Menu>
      </Box>
    </div>
  );
};

export default EditToolbar;
